<template>
    
</template>
<script>
export default {
  components: {
  },
  setup() {
    return {}
  },
  mounted(){
    // window.open('https://app.vicedigitalcreative.com')
    window.location.href = 'https://app.vicedigitalcreative.com/'
  },
}
</script>